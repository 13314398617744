<template>
<div class="card mt-4 mb-4">
    <div class="card-header">
        Szablon Service Port
        <div style="float: right">
            <div @click="showModal">
                <i class="fas fa-plus"></i>
            </div>
        </div>
    </div>
    <div class="card-body">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Nazwa</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="otl in oltTemplateServicePortList" v-bind:key="otl.Id">
                    <td><router-link :to="`/huawei/olt/${this.$route.params.id}/template/${otl.Id}/show`">{{ otl.Name }}</router-link></td>
                    <td class="text-center">X</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<router-link :to="`/huawei`">Powrót</router-link>

<Modal v-show="isModalVisible" @close="closeModal">
    <template v-slot:body>
        <form @submit.prevent="addTemplateServicePort">
            <div class="form-floating mb-3">
                <input class="form-control" id="nazwa" type="text" placeholder="Nazwa" v-model="modalDataName"/>
                <label for="nazwa">Nazwa</label>
            </div>
            <div class="form-floating mb-3">
                <select class="form-select" id="lineProfile" aria-label="LINE PROFILE" v-model="modalDataLineProfile">
                    <option v-for="lp in oltLineProfiles" v-bind:key="lp.Id" :value="lp.Id">{{ lp.Description }} ({{ lp.Id }})</option>
                </select>
                <label for="lineProfile">LINE PROFILE</label>
            </div>
            <div class="form-floating mb-3">
                <select class="form-select" id="srvProfile" aria-label="SRV PROFILE" v-model="modalDataSrvProfile">
                    <option v-for="sp in oltSrvProfiles" v-bind:key="sp.Id" :value="sp.Id">{{ sp.Description }} ({{ sp.Id }})</option>
                </select>
                <label for="srvProfile">SRV PROFILE</label>
            </div>
            <div class="d-grid">
                <button class="btn btn-primary btn-lg" id="submitButton" type="submit">Zapisz</button>
            </div>
        </form>
    </template>
</Modal>
</template>

<script>
import Modal from '../../components/Modal.vue'
import axios from 'axios'

export default {
    components: {
      Modal,
    },
    data() {
        return {
            isModalVisible: false,
            oltTemplateServicePortList: null,
            oltLineProfiles: null,
            oltSrvProfiles: null,
            modalDataName: null,
            modalDataLineProfile: null,
            modalDataSrvProfile: null,
        };
    },
    mounted() {
        this.getOltTemplateServicePortList()
    },
    methods: {
        getOltTemplateServicePortList() {
            this.oltTemplateServicePortList = null
            const data = {
                SessionId: localStorage.getItem('user'),
                HuaweiGetTemplateServicePortList: {
                    OltId: parseInt(this.$route.params.id)
                }
            };
            axios.post("/huawei/olt/template/serviceport/list", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.oltTemplateServicePortList = result.data.HuaweiGetTemplateServicePortList
            })
        },
        showModal() {
            var data = {
                SessionId: localStorage.getItem('user'),
                HuaweiGetLineProfiles: {
                    OltId: parseInt(this.$route.params.id)
                }
            };
            axios.post("/huawei/olt/getlineprofiles", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.oltLineProfiles = result.data.HuaweiGetLineProfiles
            })

            data = {
                SessionId: localStorage.getItem('user'),
                HuaweiGetSrvProfiles: {
                    OltId: parseInt(this.$route.params.id)
                }
            };
            axios.post("/huawei/olt/getsrvprofiles", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.oltSrvProfiles = result.data.HuaweiGetSrvProfiles
            })

            this.isModalVisible = true
        },
        closeModal() {
            this.isModalVisible = false
        },
        addTemplateServicePort() {
            const { modalDataName, modalDataLineProfile, modalDataSrvProfile } = this
            const data = {
                SessionId: localStorage.getItem('user'),
                HuaweiAddTemplateServicePort: {
                    OltId: parseInt(this.$route.params.id),
                    Name: modalDataName,
                    LineProfile: modalDataLineProfile, 
                    SrvProfile: modalDataSrvProfile
                }
            };

            axios.post("/huawei/olt/template/serviceport/add", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.isModalVisible = false
                this.getOltTemplateServicePortList()
            })
        }
    }
  };
</script>